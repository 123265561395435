<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "ChartComponent",
  props: {
    chartType: {
      type: String,
      required: true,
      default: "line",
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    chartType: "updateChart",
    chartData: "updateChart",
    chartOptions: "updateChart",
  },
  methods: {
    renderChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new window.Chart(this.$refs.chartCanvas, {
        type: this.chartType, // You can change this to any type of chart you want
        data: this.chartData,
        options: this.chartOptions,
      });
    },
    updateChart() {
      this.renderChart();
    },
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>

<style scoped>
/* Add any styles you need here */
</style>
